import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>プライバシーポリシー</h1>
          </div>
          <p>
            株式会社CURUCURU(以下、「弊社」と言います)は、CURUCURU
            select(以下、「当ショップ」)を運営するにあたり以下のプライバシーポリシーに則った個人情報の取扱いを行います。
          </p>
          <h4>1.個人情報の定義</h4>
          <p>
            「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。
          </p>
          <br />
          <h4>2.個人情報の収集</h4>
          <p>
            当ショップでは商品のご購入、お問合せをされた際にお客様の個人情報を収集することがございます。
            <br />
            収集するにあたっては利用目的を明記の上、適法かつ公正な手段によります。
          </p>
          <p>当ショップで収集する個人情報は以下の通りです。</p>
          <p>
            a)お名前、フリガナ
            <br />
            b)ご住所
            <br />
            c)お電話番号
            <br />
            d)メールアドレス
            <br />
            e)パスワード
            <br />
            f)配送先情報
            <br />
            g)当ショップとのお取引履歴及びその内容
            <br />
            h)上記を組み合わせることで特定の個人が識別できる情報
          </p>
          <br />
          <h4>3.個人情報の利用</h4>
          <p>
            当ショップではお客様からお預かりした個人情報の利用目的は以下の通りです。
          </p>

          <p>
            a)ご注文の確認、照会
            <br />
            b)商品発送の確認、照会
            <br />
            c)お問合せの返信時
          </p>
          <p>
            当ショップでは、下記の場合を除いてはお客様の断りなく第三者に個人情報を開示・提供することはいたしません。
          </p>
          <p>
            a)法令に基づく場合、及び国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
            <br />
            b)人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
            <br />
            c)当ショップを運営する会社の関連会社で個人データを交換する場合
          </p>
          <br />
          <h4>4.個人情報の安全管理</h4>
          <p>
            お客様よりお預かりした個人情報の安全管理はサービス提供会社によって合理的、組織的、物理的、人的、技術的施策を講じるとともに、当ショップでは関連法令に準じた適切な取扱いを行うことで個人データへの不正な侵入、個人情報の紛失、改ざん、漏えい等の危険防止に努めます。
          </p>
          <br />
          <h4>5.個人情報の訂正、削除</h4>
          <p>
            お客様からお預かりした個人情報の訂正・削除は下記の問合せ先よりお知らせ下さい。
            <br />
            また、ユーザー登録された場合、当サイトのメニュー「マイアカウント」より個人情報の訂正が出来ます。
          </p>

          <br />
          <h4>6.cookie(クッキー)の使用について</h4>
          <p>
            当社は、お客様によりよいサービスを提供するため、cookie(クッキー)を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。
            <br />
            また、cookie(クッキー)の受け入れを希望されない場合は、ブラウザの設定で変更することができます。
            <br />※
            cookie(クッキー)とは、サーバーコンピュータからお客様のブラウザに送信され、お客様が使用しているコンピュータのハードディスクに蓄積される情報です。
          </p>

          <br />
          <h4>7.SSLの使用について</h4>
          <p>
            個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的でSSL(Secure
            Sockets Layer)技術を使用しております。
            <br />※
            SSLは情報を暗号化することで、盗聴防止やデータの改ざん防止送受信する機能のことです。SSLを利用する事でより安全に情報を送信する事が可能となります。
          </p>
          <br />
          <h4>8.お問合せ先</h4>
          <p>study@tntheri.shop</p>
          <br />
          <h4>9.プライバシーポリシーの変更</h4>
          <p>
            当ショップでは、収集する個人情報の変更、利用目的の変更、またはその他プライバシーポリシーの変更を行う際は、当ページへの変更をもって公表とさせていただきます。
          </p>
          <br />
          <h4>10.広告掲載及びクッキー(Cookie)情報の利用について</h4>
          <p>
            1.当社では、LINEヤフー株式会社をはじめとする第三者から配信される広告が掲載される場合があり、これに関連して、当該第三者が、当サイトを訪問したユーザーのクッキー情報等を取得し、利用している場合があります。
          </p>
          <p>
            2.当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。
          </p>
          <p>
            3.ユーザーは、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等の広告配信への利用を停止することができます。
          </p>
          <p>
            4.クッキーとは、ウェブページを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピュータにファイルとして保存しておく仕組みです。
            <br />
            次回、同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えたりすることができます。お客様がブラウザの設定でクッキーの送受信を許可している場合、ウェブサイトは、ユーザーのブラウザからクッキーを取得できます。なお、お客様のブラウザは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。
          </p>
          <p>
            5.お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。
            <br />
            設定方法は、ブラウザにより異なります。クッキーに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
