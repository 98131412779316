import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="fsoQxs_first">
          <div className="news_info___info-sc-1gw5ec3-3">
            <p className="news_info___kindIcon-sc-1gw5ec3-0 hisZwE">INFO</p>
            <div className="news_info___content-sc-1gw5ec3-6 lgYheR">
              <p className="news_info___date-sc-1gw5ec3-4 bHKRwb">4/23(火)</p>
              <p className="news_info___title-sc-1gw5ec3-5 cHoBex">
                水曜日・カスタマー窓口お休みについて
              </p>
            </div>
          </div>
        </div>
        <div className="twoDiv">
          <img alt="" src="/main/main1.webp" />
          <img alt="" src="/main/main2.webp" />
          <img alt="" src="/main/main3.webp" />
        </div>

        {/* 最新 */}
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ marginBottom: "3%" }}>
                <h2 className="title2">最新トレンド</h2>
              </div>

              <ul className="lineup_flex11">
                <li>
                  <Link to="/">
                    <img src="/main/new1.webp" alt="天野山カントリークラブ" />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    激推し☆新作ボトムス
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img src="/main/new2.webp" alt="有馬ロイヤルゴルフクラブ" />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    注目＆限定アイテム
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/new3.webp" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    流行が知れる！
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/new4.webp" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    対象品購入で貰える
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/new5.webp" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    キャロウェイ
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/new6.webp" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    ニューエラ
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* 人気 */}
        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="title">
              <h2>
                <span>人気ランキング</span>
              </h2>
              <hr className="hr_marginb_75" />
            </div>
          </div>
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 1 && e.id <= 9)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <div className="styles_colors__VSe2z">
                          {brand.colorBac.map((color, index) => (
                            <div
                              className="styles_item_color__r27UF"
                              key={index}
                              style={{ backgroundColor: color }}
                            ></div>
                          ))}
                        </div>
                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">¥{brand.price}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ margin: "2% 0" }}>
                <h2 className="title2">ピックアップ</h2>
              </div>

              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.curucuru.jp/banner/pickup/pickup1_240913.webp"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.curucuru.jp/banner/pickup/pickup2_240913.webp"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://cdn.curucuru.jp/banner/pickup/pickup3_240913.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.curucuru.jp/banner/pickup/pickup4_240913.webp"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.curucuru.jp/banner/pickup/pickup5_240913.webp"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://cdn.curucuru.jp/banner/pickup/pickup6_240913.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*今週のイチ推しブランド*/}
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ marginBottom: "3%" }}>
                <h2 className="title2">今週のイチ推しブランド</h2>
              </div>

              <ul className="lineup_flex22">
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/brandindex/brand-title_jr.gif"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    ジュン＆ロぺ
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/brandindex/brand-title_tg.gif"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    テーラーメイドゴルフ
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/brandindex/brand-title_as.gif"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    アンサネス
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/brandindex/brand-title_lv.gif"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    ランバン スポール
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/brandindex/brand-title_hk.gif"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    ヒールクリーク
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/brandindex/brand-title_jl.gif"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    ジェイリンドバーグ
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">コーディネート</h2>
              </div>

              <ul className="lineup_flex33">
                <img
                  src="	https://www.curucuru-select.com/coordinates/coordinate_top_pc.webp"
                  alt="天野山カントリークラブ"
                />
              </ul>
            </div>
          </div>
        </div>

        {/* 人気 */}
        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="title">
              <h2>
                <span>新作アイテム</span>
              </h2>
              <hr className="hr_marginb_75" />
            </div>
          </div>
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 10 && e.id <= 18)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">¥{brand.price}(税込)</h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">女性ゴルファー向けコラム</h2>
              </div>

              <ul className="lineup_flex44">
                <li>
                  <Link to="/">
                    <img
                      src="https://d1psythrqsf08t.cloudfront.net/uploaded/0912top_blog_3eb9bed25b.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    徹底解説！スカート丈大解剖
                    <br />
                    2024/09/12
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://d1psythrqsf08t.cloudfront.net/uploaded/0911top_blog_d4140a9a7d.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    秋もテーラーメイドでウマ見え
                    <br />
                    2024/09/11
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://d1psythrqsf08t.cloudfront.net/uploaded/0905top_blog_60975d17e0.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    ジャックバニー ノベルティ対象アイテムの推しコーデ
                    <br />
                    2024/09/05
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://d1psythrqsf08t.cloudfront.net/uploaded/1_93ee9b987f.png"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    今日のバイヤー推し！～おすすめニットベスト3選～
                    <br />
                    2024/09/02
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://d1psythrqsf08t.cloudfront.net/uploaded/0829top_blog_5dff20b722.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    やっぱりはずさない！ニューバランス
                    <br />
                    2024/08/29
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://d1psythrqsf08t.cloudfront.net/uploaded/0828top_blog_abf3f712d2.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    みんなが保存している！お気に入りランキングTOP20
                    <br />
                    2024/08/28
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">今知りたい！おすすめ情報</h2>
              </div>

              <ul className="lineup_flex55">
                <li>
                  <Link to="/">
                    <img src="/main/1001.webp" alt="天野山カントリークラブ" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src="/main/1002.webp" alt="有馬ロイヤルゴルフクラブ" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/1003.webp" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">トレンド情報やキャンペーンなど配信中</h2>
              </div>

              <ul className="lineup_flex55">
                <li>
                  <Link to="/">
                    <img src="/main/1004.jpg" alt="天野山カントリークラブ" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src="/main/1005.jpg" alt="有馬ロイヤルゴルフクラブ" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img src="/main/1006.jpg" alt="福岡雷山ゴルフ倶楽部" />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
