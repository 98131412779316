import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [card, setCard] = useState("");
  const [date, setDate] = useState("");
  const [security, setSecurity] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [ship, setShip] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [cardName, setCardName] = useState("");
  const [contact, setContact] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [company, setCompany] = useState("");
  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePlaceOrder = () => {
    const newErrors = {};
    const cardInputs = document.querySelectorAll(
      'input[name="date"], input[name="security"], input[name="cardName"],input[name="contact"], input[name="firstName"], input[name="lastName"], input[name="address"],input[name="city"], input[name="postalCode"],input[name="cardName"]'
    );
    cardInputs.forEach((input) => {
      if (input.tagName === "SELECT" || input.tagName === "INPUT") {
        if (!input.value) {
          newErrors[input.name] = "该项目必须填写";
          input.style.border = "2px solid red";
        } else {
          input.style.border = "";
        }
      }
    });
    if (!contact) newErrors.contact = "Enter an email or phone number";
    if (!postalCode) newErrors.postalCode = "Enter a ZIP / postal code";
    if (!date) newErrors.date = "Enter a valid expiration date";
    if (!card) newErrors.card = "Enter a card number";
    if (!security)
      newErrors.security = "Enter the CVV or security code on your card";
    if (!firstName) newErrors.firstName = "Enter a first name";
    if (!lastName) newErrors.lastName = "Enter a last name";
    if (!address) newErrors.address = "Enter an address";
    if (!city) newErrors.city = "Enter a city";
    if (!cardName)
      newErrors.cardName =
        "Enter your name exactly as it’s written on your card";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all fields");
    } else {
      alert("Order placed!");
      clearCart();
      navigate("/paysuccess");
    }
  };
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity;
  };

  const formatCardNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "");
    value = value.replace(/\d{4}(?=\d)/g, "$& ");
    value = value.substring(0, 19);
    setCard(value);
  };

  const formatDate = (e) => {
    let { value } = e.target;
    // 只允许输入数字和斜杠
    value = value.replace(/[^\d/]/g, "");
    if (value.length > 1 && value.indexOf("/") === -1) {
      value += "/";
    }
    value = value.substring(0, 5);
    setDate(value);
  };

  return (
    <>
      <div className="check-heads">
        <div className="bag-div">
          <Link to="/cartpage" className="to-add" onClick={toTop}>
            <img
              width="26"
              height="26"
              src="https://img.icons8.com/fluency-systems-regular/50/1773b0/shopping-bag--v1.png"
              alt="shopping-bag--v1"
            />
          </Link>
        </div>
      </div>
      <div className="main-containerCheck">
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <form>
                <div className="form-group1 form-group-half1">
                  <label>連絡先</label>
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    value={contact}
                    placeholder="Eメール"
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                  {errors.contact && <p className="error">{errors.contact}</p>}
                </div>

                <div className="form-group1 form-group-half1">
                  <label>配達</label>
                  <br />
                  <select
                    name="countryCode"
                    id="Select0"
                    required=""
                    autocomplete="shipping country"
                    className="ZHJU6 _1fragemxc oAlPg IWR5K tu1VS"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <option value="">国 / 地域</option>
                    <option value="JP">日本</option>
                    <option value="KR">韓国</option>
                  </select>
                </div>

                <div className="firstLast">
                  <div className="form-group1 form-group-half1">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      placeholder="姓（例：山田）"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      style={{ width: "282.5px" }}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="form-group1 form-group-half">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      placeholder="名（例：太郎）"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      style={{ width: "282.5px" }}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                </div>

                <div className="form-group1 form-group-half1">
                  <input
                    type="text"
                    id="company-name"
                    name="company-name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="郵便番号（例：150-0001）"
                  />
                </div>

                <div className="form-group1 form-group-half1">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="以降の住所（例：神宮前1-1-1）"
                  />
                  {errors.address && <p className="error">{errors.address}</p>}
                </div>

                <div className="form-group1 form-group-half1">
                  <input
                    type="text"
                    id="address1"
                    name="address1"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    required
                    placeholder="建物名、部屋番号など（例：レジデンス　1000号室）"
                  />
                </div>

                <div className="firstLast">
                  <div className="form-group1 form-group-half1">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={city}
                      placeholder="都道府県"
                      onChange={(e) => setCity(e.target.value)}
                      required
                      style={{ width: "282.5px" }}
                    />
                    {errors.city && <p className="error">{errors.city}</p>}
                  </div>
                  <div className="form-group1 form-group-half">
                    <input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={postalCode}
                      placeholder="市区町村（例：渋谷区）"
                      onChange={(e) => setPostalCode(e.target.value)}
                      required
                      style={{ width: "282.5px" }}
                    />
                    {errors.postalCode && (
                      <p className="error">{errors.postalCode}</p>
                    )}
                  </div>
                </div>

                <div className="form-group1 form-group-half1">
                  <label>配送方法</label>
                  <input
                    type="text"
                    id="ship"
                    name="ship"
                    value={ship}
                    onChange={(e) => setShip(e.target.value)}
                    required
                    placeholder="配送先住所を入力して、利用可能な配送方法を確認してください。"
                  />
                </div>

                <br />
                <br />
                <div className="form-group1 form-group-half1">
                  <label>支払い</label>
                  <p style={{ width: "300px", height: "6px" }}>
                    すべての取引は安全で、暗号化されています。
                  </p>
                  <div className="cNWe1">
                    <div className="uAECz ">
                      <div className="uAECz-left">クレジットカード</div>
                      <div className="uAECz-right">
                        <img alt="" src="/head-foot/card1.svg" />
                        <img alt="" src="/head-foot/card2.svg" />
                        <img alt="" src="/head-foot/card3.svg" />
                        <img alt="" src="/head-foot/card4.svg" />
                        <img alt="" src="/head-foot/card5.svg" />
                        <img alt="" src="/head-foot/card6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="_1fragemr6 _4lLjv">
                    <input
                      type="text"
                      value={card}
                      name="cardNumber"
                      onChange={formatCardNumber}
                      placeholder="カード番号"
                    />
                    {errors.card && <p className="error">{errors.card}</p>}
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        value={date}
                        name="date"
                        onChange={formatDate}
                        placeholder="有効期限 (月/年)"
                        style={{ width: "266.5px", marginRight: "2%" }}
                      />
                      <input
                        type="text"
                        value={security}
                        name="security"
                        onChange={(e) => setSecurity(e.target.value)}
                        placeholder="セキュリティコード"
                        style={{ width: "266.5px" }}
                      />
                    </div>
                    <input
                      type="text"
                      value={cardName}
                      name="cardName"
                      onChange={(e) => setCardName(e.target.value)}
                      placeholder="カードの名義人"
                    />
                    {errors.cardName && (
                      <p className="error">{errors.cardName}</p>
                    )}
                  </div>
                </div>
              </form>
              <button
                className="check-checkout-button"
                onClick={handlePlaceOrder}
              >
                今すぐ支払う
              </button>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              <div className="check-order-details">
                {cartItems.map((item, index) => {
                  const product = getProductById(item.id);
                  return (
                    <div key={index} className="check-cart-item">
                      <img src={product.images[0]} alt="Product" />
                      <div className="pro--qty">
                        <div className="qty-inner">{item.quantity}</div>
                      </div>
                      <div className="check-item-details">
                        <h3>{product.name}</h3>
                        <span>
                          <p className="check-pre-price">{item.size}</p>
                        </span>
                        <span>
                          <p className="check-pre-price">{item.color}</p>
                        </span>
                      </div>
                      <p className="cal-single">
                        {calculateItemTotal(
                          item.id,
                          item.quantity,
                          item.size
                        ).toFixed(2)}
                        円
                      </p>
                    </div>
                  );
                })}

                <div className="check-summary-details">
                  <div className="gridTotal">
                    <table className="summary-details22">
                      <tbody className="sumTbody22">
                        <tr className="sumTr22">
                          <th
                            className="subtotalTh22"
                            style={{ fontSize: "24px" }}
                          >
                            合計
                          </th>
                          <th className="subtotalTxt22">
                            {calculateTotal().toFixed(2)}円
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
