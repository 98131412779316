import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!name) newErrors.name = "Enter a name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("Send Success!");
      setName("");
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>お問い合わせ</h1>
          </div>
          <p>
            その他、当ショップへのご意見・ご感想・お問い合わせは、下記よりお願いいたします。
          </p>
          <p>
            ◯お問い合わせ窓口に関して
            <br />
            【定 休 日】：日曜日
            <br />
            【営業時間】：10時〜16時
          </p>
          <div className="contact-form form-vertical">
            <form
              id="ContactForm"
              acceptCharset="UTF-8"
              className="contact-form"
              onSubmit={contactSubmit}
            >
              <div className="grid grid--half-gutters">
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-name">お名前 （必須）</label>
                  <input
                    type="text"
                    id="ContactForm-name"
                    name="contact[名前]"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-email">
                    メールアドレス （必須）
                  </label>
                  <input
                    type="email"
                    id="ContactForm-email"
                    name="contact[email]"
                    autoCorrect="off"
                    autoCapitalize="off"
                    required
                    aria-required="true"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
              </div>
              <label htmlFor="ContactForm-phone">
                お問い合わせタイトル （必須）
              </label>
              <input
                type="tel"
                id="ContactForm-phone"
                name="contact[電話番号]"
                pattern="[0-9\-]*"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label htmlFor="ContactForm-message">
                お問い合わせ内容 （必須）
                <br />
                <small>ご入力は「全角」でお願いいたします。</small>
                <br />
                <small>
                  【内容】【連絡先（自宅／携帯）】をご入力ください。
                </small>
              </label>
              <textarea
                rows="10"
                id="ContactForm-message"
                name="contact[メッセージ]"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="商品ID：1000005288"
              ></textarea>
              <input type="submit" className="btn" value="送信" />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
