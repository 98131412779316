import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Term() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>利用規約</h1>
          </div>
          <h4>CURUCURU select 利用規約</h4>
          <p>
            1.「CURUCURU
            select」とは、株式会社CURUCURU(以下「当社」といいます)が運営するウェブサイトです。
            <br />
            2.株式会社CURUCURUは、「CURUCURU
            select」(以下「本サービス」といいます)におけるサービスの利用規約(以下「本規約」といいます)を以下の通り定めます。
          </p>
          <br />
          <h4>第1章 総則</h4>
          <h4>第1条 本規約の範囲及び変更</h4>
          <p>
            1.本規約は、当社が運営する本サービスの利用に関する規約を定めたものです。
            <br />
            2.本規約は、本サービスの利用に関して、利用者(第3条で定義します)全てに適用されるものとします。
            <br />
            3.当社は、利用者の事前の承諾を得ることなく、事前の了承を得ることなく本規約を変更できるものとし、利用者はこれを承諾するものとします。
            <br />
            4.本規約の全部又は一部が変更された場合、本サービスの利用に関しては、変更後の規約が適用されるものとし、利用者は変更後の規約のみに従うものとします。
            <br />
            5.本規約の変更は、当社が適切と考える手段によって随時利用者に告知するものとします。
          </p>
          <h4>第2条 本サービスの利用</h4>
          <p>
            利用者は、関係する法令等並びに本規約及び当社が別途定めるその他の条件に従い、本サービスを利用するものとします。
          </p>
          <h4>第2章 利用者</h4>
          <h4>第3条 利用者</h4>
          <p>
            本規約において、「利用者」とは、当サイトの閲覧、当サイトに掲げた商品の購入、その他本サービスを利用する者をいいます。本サービスを利用した場合、利用者は本
            <br />
            規約に同意したものとみなされます。
            <br />
            なお、下記4条で定義するCURUCURU
            selectの登録を完了されたお客様(以下「会員」と総称します)を含むものとします。
          </p>
          <h4>第3章 会員</h4>
          <h4>第4条 会員</h4>
          <p>
            会員とは、「CURUCURU select規約」を承認の上で、当社に「CURUCURU
            select」への入会を申請し、当社がその入会を承諾した後、所定の登録手続きとして専用ページにて必要情報の登録を完了された者を総称します。
          </p>
          <h4>第5条 会員登録</h4>
          <p>
            1.会員登録の希望者は、本サービスの専用ページから、氏名、性別、生年月日、郵便番号、住所、電話番号、メールアドレス、及びパスワードを設定・入力の上、会員登録申請を行うものとします。
            <br />
            2.当社は、第１項に基づく申請に対し、これを承認する場合には登録確認メールを送信し、当該メールが受領された段階で、申請を行った者を登録するものとします。
            <br />
            3.当社は、以下の各号の何れかに該当する場合、当該登録申請を承認しないものとします。
            <br />
            （1）会員登録の希望者が、過去に当社の提供する何らかのサービスに関する規約に違反したこと等により、登録の抹消等の処分を受けていたことが判明した場合
            <br />
            （2）会員登録の希望者の申請内容に虚偽の事項が含まれていることが判明した場合
            <br />
            （3）会員登録の希望者が、過去に当社の提供する何らかのサービスに関して、料金等の支払債務の履行遅延、長期間に亘る商品等の受取り不能、不当な返品・交換の要求ないしその他の債務不履行があったことが判明した場合
            <br />
            （4）過去に本規約第18条(禁止事項)の行為を行ったことが判明した場合
            <br />
            （5）その他、登録を承認することが本サービスの運営・管理上、不適当であると当社が合理的に判断する場合
          </p>
          <h4>第6条 登録内容の変更</h4>
          <p>
            1.会員は、当社に登録している事項の全部又は一部に変更が生じた場合には、当社が別途指定する方法によりすみやかに登録内容を変更するものとします。
            <br />
            2.当社は、会員が適宜、変更登録を行わなかったことにより何らかの損害が生じたとしても、一切責任を負わないものとします。
          </p>
          <h4>第7条 本サービスの利用停止及び会員登録の取消</h4>
          <p>
            当社は、会員が以下の各号の何れかに該当する場合、事前に通知することなく、当該会員に対して本サービスの利用停止、会員登録抹消その他当社が適当と考える措置を講ずることができるものとします。
            <br />
            （1）過去に当社の提供する何らかのサービスに関する規約に違反をしたこと等により、会員登録の抹消等の処分を受けていたことが判明した場合
            <br />
            （2）登録された内容に虚偽の事項が含まれていることが判明した場合
            <br />
            （3）過去に当社の提供する何らかのサービスに関して、料金等の支払債務の履行遅延、長期間に亘る商品等の受取り不能、不当な返品・交換の要求ないしその他の債務不履行があったことが判明した場合
            <br />
            （4）過去に本規約第18条(禁止事項)の行為を行ったことが判明した場合
            <br />
            （5）当社が定める何らかの規約の規定に違反した場合
            <br />
            （6）その他、本サービスの運営・管理上、会員として不適当であると当社が合理的に判断する場合
          </p>
          <h4>第8条 退会手続</h4>
          <p>会員は随時退会できるものとします。</p>
          <h4>第9条 CURUCURU select ID、メールアドレス及びパスワードの管理</h4>
          <p>
            1.会員は、CURUCURU select
            ID、メールアドレス、登録等の際に自身で設定したパスワードを厳重に管理・保管する責任を負うものとします。
            <br />
            2.会員は、当社の事前の同意がある場合を除き、CURUCURU select
            ID、メールアドレス又はパスワードを第三者に譲渡、売買、承継、貸与、開示又は漏洩してはならないものとします。
            <br />
            3.会員は、CURUCURU select
            ID、メールアドレス又はパスワードが第三者によって不正に使用されていることが判明した場合には、直ちに当社に連絡するものとします。
            <br />
            4.会員は、CURUCURU select
            ID、メールアドレス又はパスワードの管理不十分、使用上の過誤・不手際、第三者の無断使用等に起因する損害につき自ら責任をうものとし、当社は一切責任を負担しないものとします。
          </p>
          <h4>第10条 個人情報の利用</h4>
          <p>
            当社が別途定める「個人情報保護方針」の「3.個人情報の利用」に従い、本サービスの利用に関連して当社が知り得た会員の個人情報の利用目的を、以下の通り定めます。
            <br />
            （1）当社の商品の発送、カタログやＤＭ等の送付、関連するアフターサービス、新商品・サービスに関する情報のお知らせのため
            <br />
            （2）当社の通信販売に関する事業活動、催事に関するご案内のため
            <br />
            （3）マーケティングおよび販売促進、商品企画のための統計データ作成のため(但し、個人は識別できません)
          </p>
          <h4>第4章 商品の購入</h4>
          <h4>第11条 商品の購入</h4>
          <p>
            1.会員は、本サービスを利用して当社から商品等を購入することができます。
            <br />
            2.会員は、商品等の購入を希望する場合、当社が別途指定する方法に従って、商品等の購入又は利用を申込むものとします。
            <br />
            3.本サービス利用に関して不正行為又は不適当な行為があった場合、当社は売買契約について取消、解除その他の適切な措置を取ることができるものとします。
            <br />
            4.本サービスによる商品等の配送は、日本国内に限ります。
          </p>
          <h4>第12条 支払方法</h4>
          <p>
            1.商品等のお支払い金額は、消費税を含む商品等の購入代金及びこれに係わる取り扱い手数料の合計となります。
            <br />
            2.本サービスによって購入された商品等のお支払いに関しては、会員本人名義のクレジットカードによる支払、又は当社が別途認める支払方法に限るものとします。
            <br />
            3.クレジットカードによる支払の場合は、会員がクレジットカード会社との間で別途契約する条件に従うものとします。なお、クレジットカードの利用に関連して、会員とクレジットカード会社等の間で何らかの紛争が発生した場合は、会員とクレジットカード会社との間で責任をもって解決するものとします。
          </p>
          <h4>第13条 商品等の返品・交換</h4>
          <p>
            商品の返品又は交換は、商品の受け取りから４日以内に当社指定の方法により申し出た上で、別途定める条件を満たした場合に限り、受け付けるものとします。
            <br />
            また、商品の交換は、同一商品の色・サイズ交換に限るものとします。会員は、当該返品・交換に関わる商品等の一切の送料を負担するものとします。ただし、不良品、品違いその他当社の責めに帰すべき事由がある場合、当該返品・交換に関わる商品等の一切の送料は当社が負担します。なお、代品への交換を希望された場合においても、商品の欠品等を理由に交換ができないことがあります。かかる場合には当社は当該商品等に関するお支払代金を返金するものとします。
          </p>
          <h4>第14条 商品等に関する免責</h4>
          <p>
            1.当社は、本サービス及び本サービスを通じて販売される商品等につき、その品質、材質、機能、性能、他の商品との適合性その他の欠陥、及びこれらが原因となり生じた損害、損失、不利益等については、前条に定める場合を除き、いかなる保証・負担も負わないものとします。
            <br />
            2.当社は、配送先不明等によるトラブルに関しては、会員が登録している連絡先に連絡すること及び商品購入の際に指定された配達先に商品等を配送等することにより、商品等の引渡債務を履行し、当該債務から免責されるものとします。
          </p>
          <h4>第5章 サービスの利用</h4>
          <h4>第15条 免責事項</h4>
          <p>
            1.本サービスから他のウェブサイト若しくはリソースへのリンク、又は第三者のウェブサイト若しくはリソースから本サービスへのリンクを提供している場合、当社は、当該リンク先の内容、利用及びその結果については、いかなる責任も負わないものとします。(適法性、有効性、正確性、確実性、安全性、最新性及び完全性を含みますが、これらに限りません)なお、当社は、リンク先のウェブサイト又はリソースの内容が、違法又は本サービスの管理・運営上不適切であると合理的に判断した場合には、会員に何らの通知を要することなく、当該リンク先を削除することができるものとします。
            <br />
            2.当社は、以下の場合に、一時的に本サービスが停止、中止又は変更されたとしても、会員が直接的又は間接的に被った一切の損害、損失、不利益等について、いかなる責任も負わないものとします。
            <br />
            （1）火災、地震、洪水、落雷、大雪等の天変地異が生じた場合
            <br />
            （2）戦争、内乱、テロ、暴動、騒乱等の社会不安が生じた場合
            <br />
            （3）当社が契約している電話会社、運送会社又はプロバイダから適切なサービスを受けられなかった場合
            <br />
            （4）当社が技術的に対応不可能な事由が生じた場合
            <br />
            3.当社は、会員の登録内容に従い事務を処理することにより、当社の債務を履行し免責されるものとします。
            <br />
            4.会員が、本サービスを利用することにより、他の利用者又は第三者に対して何らかの損害等を与えた場合には、当該登録者はその責任と費用においてこれを解決し、当社には一切の損害、損失、不利益等を与えないものとします。
          </p>
          <h4>第16条 禁止事項</h4>
          <p>
            利用者は、以下の行為を一切行ってはならないものとします。万一、これに違反して当社又は第三者に損害が生じた場合、当該利用者がその損害を全て賠償する責任を負うものとします。
            <br />
            （1）当社に迷惑、不利益若しくは損害を与える行為、又はそれらのおそれのある行為
            <br />
            （2）本サービスを商業目的で利用する行為(但し、当社が予め認めたものは除きます)
            <br />
            （3）法令に違反する行為又はそれらのおそれのある行為
            <br />
            （4）本サービスを通じて入手したコンテンツを利用者が私的使用の範囲外で使用する行為
            <br />
            （5）他の利用者、又は他の利用者以外の第三者を介して、本サービスを通じて入手したコンテンツを複製、販売、出版、頒布、公開したりする行為及びこれらに類似する行為
            <br />
            （6）コンピューターのソフトウエア、ハードウエア、通信機器の機能を妨害、破壊、制限するように設計されたコンピューターウイルス、コンピューターコード、ファイル、プログラム等のコンテンツを本サービスにアップロードしたり、メール等の手段で送信したりする行為
          </p>
          <h4>第17条 知的財産権</h4>
          <p>
            1.本サービスを通じて提供されるコンテンツの知的財産権は、全て当社又は当社に使用許諾を与えた第三者に帰属するものとします。
            <br />
            2.目的の如何を問わず、当社のコンテンツの無断複製、無断転載、その他の無断二次利用行為等の国内及び国外の著作権法及びその他の法令により禁止される行為が発見された場合には、当社は直ちに法的措置をとるものとします。
            <br />
            3.本条の規定に違反して第三者との間で何らかの紛争が生じた場合、会員はその責任と費用において、かかる紛争を解決するとともに、当社に何らの損害、損失又は不利益等を与えないものとします。
          </p>
          <h4>第6章 サービスの運用</h4>
          <h4>第18条 情報の管理</h4>
          <p>
            1.当社は利用者のアクセス履歴及び利用状況の調査のため、又は利用者へのサービス向上のために利用者のアクセス履歴に関する下記の情報を収集します。
            <br />
            （1）利用者が本サービスのサーバーにアクセスする際のIPアドレス又は携帯端末の機体識別番号に関する情報
            <br />
            （2）当社が、クッキーの技術(Webブラウザを通じてユーザーのコンピュータに一時的にデータを書き込んで利用者が最後にサイトを訪れた日時、そのサイトの訪問回数等を記録保存する技術をいいます)を通じて取得する利用者のアクセス情報
          </p>
          <p>
            2.利用者は、利用者がWebブラウザでクッキーを拒否するための設定を行った場合、本サービスの利用が制限される場合があることを予め了承するものとします。
          </p>
          <h4>第19条 本サービスの保守</h4>
          <p>
            当社は、本サービスの稼動状態を良好に保つため、以下各号の何れかの場合には、利用者に事前に通知を行うことなく、一時的に本サービスの提供の全部又は一部を停止又は中止することができるものとします。
            <br />
            （1）本サービス提供のためのコンピューターシステム(以下「システム」といいます)の定期保守及び緊急保守の場合
            <br />
            （2）火災、地震、洪水、落雷、大雪等の天変地異により、システムの運用が困難になった場合
            <br />
            （3）戦争、内乱、テロ、暴動、騒擾等の社会不安により、システムの運用が困難になった場合
            <br />
            （4）システムの不良及び第三者からの不正アクセス、コンピューターウイルスの感染等により、システムの運用が困難になった場合
            <br />
            （5）行政機関・司法機関から相当な根拠に基づき要請された場合
            <br />
            （6）その他やむを得ずシステムの停止又は中止が必要と当社が判断した場合
          </p>
          <h4>第20条 その他</h4>
          <p>
            1.本サービスの利用に関して、本規約又は当社の指導・対応により解決できない問題が生じた場合には、当社と利用者との間で双方誠意をもって話し合い、これを解決するものとします。
            <br />
            2.本サービスの利用に関して訴訟の必要が発生した場合には、名古屋地方裁判所または名古屋簡易裁判所を第一審の管轄裁判所にするものとします。
          </p>
          <br />
          <p>
            付則：本規約は2017年9月17日から全ての利用者に適用されるものとします。
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Term;
