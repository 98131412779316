const productData = [
  {
    id: 1,
    name: "ビームスゴルフリブモックネック半袖スウェット",
    main: "ビームスゴルフ",
    cent: "154",
    colorBac: ["red", "navy", "gray"],
    description: [
      "・サラッと優しい肌当たりのスウェット生地",
      "・やや薄手で柔らかいので肌に馴染む",
      "・両サイドにポケットあり(計2つ)",
      "・左胸と右袖、背肩部にロゴプリント",
      "・安心品質の日本製",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッション性と機能性を両立させたBEAMSのオリジナルブランド。アメカジやアウトドアのテイストを取り入れた＜オレンジレーベル＞と、伝統的なゴルフスタイルをシルエットや色使いで、モダナイズした＜パープルレーベル＞の2ラインに加え、遊び心に溢れたデザインでプレーを楽しく演出してくれるアクセサリー類のラインアップ。ゴルフがもっと楽しくなるビームスならではのゴルフスタイルは、着実にファンを増やしている。",
    ],
    price: "15400",
    sizeImg: "/curu/size01.png",
    size: ["16(S)", "18(M)", "20(L)"],
    color: ["杢グレー", "レッド", "ネイビー"],
    brand: "	ビームスゴルフ",
    catagory: "	半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "83030068803",
    season: "F/W(秋冬)",
    images: [
      "/curu/1001.jpg",
      "/curu/1001a.webp",
      "/curu/1001b.webp",
      "/curu/1001c.webp",
      "/curu/1001d.webp",
      "/curu/1001e.webp",
      "/curu/1001f.webp",
      "/curu/1001g.webp",
      "/curu/1001h.webp",
      "/curu/1001i.webp",
      "/curu/1001j.webp",
      "/curu/1001k.webp",
      "/curu/1001l.webp",
      "/curu/1001m.webp",
    ],
  },
  {
    id: 2,
    name: "アンサネス一体型ペチパンツ付バックフレアスカート",
    main: "アンサネス",
    cent: "94",
    colorBac: ["gray", "khaki", "white"],
    description: [
      "アンサネスで人気のBACKフレアスカートの新作。より軽さのあるソフトな薄手ダンボールニット素材に変えて軽やかな着心地になりました！ シルエットは従来と変わらず、フロントのフラットなシンプルさとBACKのフレア切替によるCUTEさの前後の印象のギャップがPOINTになっています。 BACKの配色のパイピングラインは主張しすぎず、なじませすぎないカラーを選びました。 カーブラインが女性らしいHIPの丸みをより美しく見せてくれます。 色違いやリピート買いされる方も多い、アンサネスの自信作です◎",
      "新素材も柔らかくストレッチ性の良い生地を採用しているので、従来と変わらずアクティブな動きをしっかりサポート。太めのウエストゴムベルト使用で、ストレスフリーな着用感はやみつきになりますよ♪ また、お客様の声にお応えしてBACKポケットを1つから2つに増やしました！　D管付き、インナーパンツ一体型なのは変わらずです。ラーは合わせやすいなじみカラーを意識して、ホワイト・オリーブ・ライトグレーの3色展開です。",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "「Shine on your story」スポーツを愛する女性の為に、2021年に誕生したアクティブライフスタイルウェアブランド「ANSUNESS」。",
      "ナチュラルな時も、アクティブな時も、365日太陽のように寄り添って、あなたの物語を照らします。スタイリングに悩まないBASICさと、着心地の良さ、コストパフォーマンスの高さが魅力の注目ブランドです。",
    ],
    price: "9460",
    sizeImg: "/curu/size02.png",
    size: ["S", "M", "L"],
    color: ["オリーブ", "ライトグレー", "ホワイト"],
    brand: "	アンサネス",
    catagory: "	スカート",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "AS42B003",
    season: "S/S(春夏)",
    images: [
      "/curu/1002.webp",
      "/curu/1002a.webp",
      "/curu/1002b.webp",
      "/curu/1002c.webp",
      "/curu/1002d.webp",
      "/curu/1002e.webp",
      "/curu/1002f.webp",
      "/curu/1002g.webp",
      "/curu/1002h.webp",
      "/curu/1002i.webp",
      "/curu/1002j.webp",
      "/curu/1002k.webp",
      "/curu/1002l.webp",
      "/curu/1002m.webp",
    ],
  },
  {
    id: 3,
    name: "キャロウェイ選べるデザインプリーツスカート",
    main: "キャロウェイ",
    cent: "154",
    colorBac: ["blown", "beige", "white"],
    description: [
      "・シーズンムードが高まる柄でコーデの主役になるスカート",
      "・切替えのプリーツでAラインのシルエット",
      "・左サイドファスナーでシルエットも綺麗",
      "・ポケットは4つ",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "着る人、見る人を魅了する。ゴルフは、国境・性別・年齢の枠を超えて誰もが楽しめる唯一無二なスポーツ。プロも認める機能性、良質な生地・着心地。アパレルブランドが作る、安心感。 ゴルフシーンを最大限にENJOYするためのキャロウェイ。をコンセプトに時代に合ったアパレルの流行を適度に取り入れつつ、機能的で使いやすいウェアが多いため、幅広い世代のゴルファーに支持されています。",
    ],
    price: "15400",
    sizeImg: "/curu/size03.png",
    size: ["SS", "S", "M", "L"],
    color: [
      "ホワイト×千鳥",
      "ベージュ×グレンチェック",
      "ブラウン×グレンチェック",
    ],
    brand: "キャロウェイ",
    catagory: "スカート",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "C24228207",
    season: "F/W(秋冬)",
    images: [
      "/curu/1003.webp",
      "/curu/1003a.webp",
      "/curu/1003b.webp",
      "/curu/1003c.webp",
      "/curu/1003d.webp",
      "/curu/1003e.webp",
      "/curu/1003f.webp",
      "/curu/1003g.webp",
      "/curu/1003h.webp",
      "/curu/1003i.webp",
      "/curu/1003j.webp",
      "/curu/1003k.webp",
      "/curu/1003l.webp",
      "/curu/1003m.webp",
    ],
  },
  {
    id: 4,
    name: "ジャックバニージャガードロゴモックネック半袖カットソー",
    main: "ジャックバニー",
    cent: "121",
    colorBac: ["gray", "pink", "black", "white"],
    description: [
      "ジャガードロゴでインパクトを与えたリブ衿がデザインポイントになった、半袖モックネックカットソー。大好評の定番ジャガードモックネックアイテムを、今期は新しいマイクロサイズのワッペンを胸元にシンプルにポイントしています。",
      "さらっとした肌離れのいい鹿の子素材。程よいリラックスサイズで仕立てているので、カジュアルからキレイめコーデまで幅広いスタイルに対応します。ホワイトは透け感があります。",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "Swing Everyday！！毎日をもっとスイングしよう、ゴルフをもっと日常へ！！をテーマに、家族みんなが楽しめるゴルフウェアを展開。 ウサギをモチーフにしたアイテムなど豊富な小物の人気が高く、常にワクワク感を提供してくれる。",
    ],
    price: "12100",
    sizeImg: "/curu/size04.png",
    size: ["0", "1", "2"],
    color: ["ホワイト", "ブラック", "グレー", "ピンク"],
    brand: "ジャックバニー",
    catagory: "	半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "2634267716",
    season: "F/W(秋冬)",
    images: [
      "/curu/1004.webp",
      "/curu/1004a.webp",
      "/curu/1004b.webp",
      "/curu/1004c.webp",
      "/curu/1004d.webp",
      "/curu/1004e.webp",
      "/curu/1004f.webp",
      "/curu/1004g.webp",
      "/curu/1004h.webp",
      "/curu/1004i.webp",
      "/curu/1004j.webp",
      "/curu/1004k.webp",
      "/curu/1004l.webp",
      "/curu/1004m.webp",
    ],
  },
  {
    id: 5,
    name: "アンサネス【着圧】アクティブUVストッキングトレンカ",
    main: "アンサネス",
    cent: "22",
    colorBac: ["beige"],
    description: [
      "アンサネスからトレンカタイプのUVストッキングが登場！ ヒップをキュッと上に引き上げてくれるヒップアップサポート編みが嬉しい。脚をより美しく見せてくれるよう、血色がよく見えるピンク味のあるベージュカラーを採用し、素肌見えにもこだった40デニールのストッキング。",
      "伸縮性、耐久性、サポート性に優れた「ゾッキ編み」を採用。 UVカット効果はUPF50+あり安心、さらに抗菌防臭加工もされているので気になるニオイにもアプローチしてくれます◎ つま先の出るトレンカタイプはサンダルに合わせて普段使いするのもオススメです！ サポート力を高めるために着圧度が高くタイトな作りとなっています。リラックスした着用感がお好みの方は1サイズ上を選んでみてください。",
      "※ゴルフカート等ナイロンメッシュ座面での引っかけにご注意ください。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "「Shine on your story」スポーツを愛する女性の為に、2021年に誕生したアクティブライフスタイルウェアブランド「ANSUNESS」。",
      "ナチュラルな時も、アクティブな時も、365日太陽のように寄り添って、あなたの物語を照らします。スタイリングに悩まないBASICさと、着心地の良さ、コストパフォーマンスの高さが魅力の注目ブランドです。",
    ],
    price: "2200",
    sizeImg: "/curu/size05.png",
    size: ["M", "L"],
    color: ["ベージュ"],
    brand: "アンサネス",
    catagory: "	レギンス・タイツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "AS32A002",
    season: "S/S(春夏)",
    images: [
      "/curu/1005.webp",
      "/curu/1005a.webp",
      "/curu/1005b.webp",
      "/curu/1005c.webp",
      "/curu/1005d.webp",
      "/curu/1005e.webp",
      "/curu/1005f.webp",
      "/curu/1005g.webp",
      "/curu/1005h.webp",
      "/curu/1005i.webp",
      "/curu/1005j.webp",
      "/curu/1005k.webp",
      "/curu/1005l.webp",
    ],
  },
  {
    id: 6,
    name: "ニューバランスゴルフ【ストレッチ】ハーフジップ半袖プルオーバー",
    main: "ニューバランスゴルフ",
    cent: "143",
    colorBac: ["white", "black", "gray"],
    description: [
      "・サラッとした肌触り",
      "・程よい厚みのある生地でラインを拾いにくい",
      "・ファスナー引手が大きくてつかみやすい&アクセントに",
      "・フロントにロゴワッペン、左袖にロゴプリントあり",
      "・「TEXBRID」→優れたストレッチ性&キックバック性で身体の動きに合わせて伸縮する",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "機能性を軸にファッション感覚を併せ持つ「WORLD」と、ファッショントレンド要素に機能面も兼備した「SPORT」の２ラインで構成。",
      "ニューバランスのコンセプトや哲学を大切に、ファッションを意識した新たなゴルフスタイルを発信しています",
    ],
    price: "14300",
    sizeImg: "/curu/size06.png",
    size: ["0", "1", "2"],
    color: ["杢グレー", "ブラック", "ホワイト"],
    brand: "ニューバランスゴルフ",
    catagory: "半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "0124262506",
    season: "F/W(秋冬)",
    images: [
      "/curu/1006c.webp",
      "/curu/1006.webp",
      "/curu/1006a.webp",
      "/curu/1006b.webp",
      "/curu/1006d.webp",
      "/curu/1006e.webp",
      "/curu/1006f.webp",
      "/curu/1006g.webp",
      "/curu/1006h.webp",
      "/curu/1006i.webp",
      "/curu/1006j.webp",
      "/curu/1006k.webp",
      "/curu/1006l.webp",
      "/curu/1006m.webp",
    ],
  },
  {
    id: 7,
    name: "アンサネス【ストレッチ】ノースリーブフーディプルオーバー",
    main: "アンサネス",
    cent: "77",
    colorBac: ["pink", "black", "white"],
    description: [
      "・大きめフードがカジュアル&スポーティー",
      "・そのままでもフードがしっかり立つように、フード内側にリボンテープで芯を施している",
      "・暑い時期は1枚で、涼しくなったら長袖トップス合わせで長く着用できる",
      "・フード部分に大きくロゴを配している",
      "・カンガルーポケットになっているのでたくさん入り、出し入れもしやすい",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "「Shine on your story」スポーツを愛する女性の為に、2021年に誕生したアクティブライフスタイルウェアブランド「ANSUNESS」。",
      "ナチュラルな時も、アクティブな時も、365日太陽のように寄り添って、あなたの物語を照らします。スタイリングに悩まないBASICさと、着心地の良さ、コストパフォーマンスの高さが魅力の注目ブランドです。",
    ],
    price: "7700",
    sizeImg: "/curu/size07.png",
    size: ["S", "M", "L"],
    color: ["ブラック", "ピンク", "ホワイト"],
    brand: "	アンサネス",
    catagory: "	ノースリーブ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "AS44T002",
    season: "F/W(秋冬)",
    images: [
      "/curu/1007.webp",
      "/curu/1007a.webp",
      "/curu/1007b.webp",
      "/curu/1007c.webp",
      "/curu/1007d.webp",
      "/curu/1007e.webp",
      "/curu/1007f.webp",
      "/curu/1007g.webp",
      "/curu/1007h.webp",
      "/curu/1007i.webp",
      "/curu/1007j.webp",
      "/curu/1007k.webp",
      "/curu/1007l.webp",
      "/curu/1007m.webp",
    ],
  },
  {
    id: 8,
    name: "アディダスゴルフ【防風撥水】ハーフジップ半袖プルオーバー",
    main: "アディダスゴルフ",
    cent: "154",
    colorBac: ["white", "black"],
    description: [
      "・さらっとしたタフタ素材",
      "・ハーフジップ仕様で着脱しやすい",
      "前後にはロゴデザインがあしらわれている",
      "・両サイドにはウエストドロストあり",
      "・裾ドロスト付き",
      "・ポケットはファスナー付きが2つ",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "最新のテクノロジーを取り入れ、365日戦い抜くファッション性・機能性の高いアイテムを提案。",
      "ゴルフの動作や環境に合わせた機能は、幅広いゴルファーに支持される。ハイパフォーマンスに加え、着る人を満足させるデザイン性にも注目。",
    ],
    price: "15400",
    sizeImg: "/curu/size08.png",
    size: ["XS", "S", "M", "L"],
    color: ["ブラック", "ホワイト"],
    brand: "アディダスゴルフ",
    catagory: "半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "JSC21",
    season: "	F/W(秋冬)",
    images: [
      "/curu/1008.webp",
      "/curu/1008a.webp",
      "/curu/1008b.webp",
      "/curu/1008c.webp",
      "/curu/1008d.webp",
      "/curu/1008e.webp",
      "/curu/1008f.webp",
      "/curu/1008g.webp",
      "/curu/1008h.webp",
      "/curu/1008i.webp",
      "/curu/1008j.webp",
      "/curu/1008k.webp",
      "/curu/1008l.webp",
      "/curu/1008m.webp",
    ],
  },
  {
    id: 9,
    name: "パーリーゲイツロゴ刺繍キャンバス地スタンドキャディバッグ",
    main: "パーリーゲイツ",
    cent: "514",
    colorBac: ["navy", "green", "pink"],
    description: [
      "随所にロゴ刺繍がデザインされた、アイコニックなキャディバッグ。ネイビーはシンプルな形のロゴ&ベーシックカラーで使いやすく、他2色はペールカラー&マリンテイストなロゴがCute♪ サイドと口枠の爽やかなメッシュ地がデザインアクセントに。",
      "しっかりとしたキャンバス地なので重量感あり。46インチクラブ対応で、6つの口枠仕様でバランスの良いクラブ収納が可能。ポーチとして使用できる取り外し可能な立体ポケット付きが便利！ 他にも多数のポケットを備え、アイテム収納に困りません◎ 肩や背中の負担を軽減するクッション性の良いショルダーパッドや、ネームプレート、ループ、傘用ループなど、機能性に優れたバッグです。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "1989年、数多くのアパレルメーカーをもつ元サンエー・インターナショナルのスタッフが、ゴルフを始めるのに「着たいゴルフウェアがない！」から作ってしまおう、と生まれた'パーリーゲイツ'。",
      "「もっと気軽にもっと楽しくゴルフをしよう」というコンセプトのもと、あらゆる枠組みを超えた新しいゴルフウェアとゴルフ本来の素晴らしさを、パーリーゲイツは提案し続けている。 ゴルフプレーヤーなら知らない人はいないほど、絶大な人気を誇る'パーリーゲイツ'から、常に目がはなせない！",
    ],
    price: "51480",
    sizeImg: "/curu/size09.png",
    size: ["フリー"],
    color: ["ペールピンク", "ネイビー", "ペールグリーン"],
    brand: "	パーリーゲイツ",
    catagory: "	キャディバッグ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "0533180500",
    season: "All",
    images: [
      "/curu/1009.webp",
      "/curu/1009a.webp",
      "/curu/1009b.webp",
      "/curu/1009c.webp",
      "/curu/1009d.webp",
      "/curu/1009e.webp",
      "/curu/1009f.webp",
      "/curu/1009g.webp",
      "/curu/1009h.webp",
      "/curu/1009i.webp",
      "/curu/1009j.webp",
      "/curu/1009k.webp",
      "/curu/1009l.webp",
      "/curu/1009m.webp",
    ],
  },

  // 下面不用colorBac
  {
    id: 10,
    name: "ピンMr.PINGバックプリントプルオーバー",
    main: "ピン",
    cent: "143",
    description: [
      "・程よいハリ感でサラッとドライタッチな素材",
      "・程よい薄さで軽い着心地",
      "・フロントのラバーワッペンにはエンボスロゴいり",
      "・バックには大きなMr.PINGのロゴプリントが映える",
      "・左サイドにロゴ入りのアクセントテープが目を惹くデザイン",
      "・ホワイトはやや透け感あり",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "世界的なゴルフクラブブランドである「PING」のアパレル",
      "ブランドコンセプトは【FEEL NEW. PLAY COOL.】時流に捉われず、独自のスタイルで洗練されたゴルフファッションを提案し、ファッションの楽しさとゴルフの素晴らしさを伝えます。",
    ],
    price: "14300",
    sizeImg: "/curu/size010.png",
    size: ["S", "M", "L"],
    color: ["ネイビー", "ホワイト", "ベージュ"],
    brand: "	ピン",
    catagory: "プルオーバー",
    shipDate: "	当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "6224262902",
    season: "F/W(秋冬)",
    images: [
      "/curu/10010.webp",
      "/curu/10010a.webp",
      "/curu/10010b.webp",
      "/curu/10010c.webp",
      "/curu/10010d.webp",
      "/curu/10010e.webp",
      "/curu/10010f.webp",
      "/curu/10010g.webp",
      "/curu/10010h.webp",
      "/curu/10010i.webp",
      "/curu/10010j.webp",
      "/curu/10010k.webp",
      "/curu/10010l.webp",
      "/curu/10010m.webp",
    ],
  },
  {
    id: 11,
    name: "ディッセンバーメイ一体型ペチパンツ付きロゴテープスカート",
    main: "ビバハート",
    cent: "330",
    description: [
      "・サラッと程良いハリ感のある素材で、ストレッチ性◎",
      "・ロゴテープとスナップボタンを組み合わせた個性的なベルトループ",
      "・ベルトループとサイドにロゴ入りテープ、バックにロゴ刺繍を配置",
      "・ラウンドした裾がCUTE",
      "・ハイストレッチな一体型ペチパンツは適度な厚みがあり安心",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッションとスポーツを両軸に、音楽やストリートなどのサブカルチャーと歴史に裏打ちされたアーカイブを尊重したバランス感のあるハイブリッドウェアを展開。 身に着ける人の個性や環境に合わせ対応する製品を東京という都市の持つキャラクターをベースに表現します。 変えていくべきこと × 変えてはならないものを異なる要素を組み合わせて独自のスタイルを発信していくブランド。",
    ],
    price: "33000",
    sizeImg: "/curu/size011.png",
    size: ["S", "M"],
    color: ["オフホワイト", "ベージュ", "ブラック"],
    brand: "ディッセンバーメイ",
    catagory: "スカート",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "24052523",
    season: "F/W(秋冬)",
    images: [
      "/curu/10011.webp",
      "/curu/10011a.webp",
      "/curu/10011b.webp",
      "/curu/10011c.webp",
      "/curu/10011d.webp",
      "/curu/10011e.webp",
      "/curu/10011f.webp",
      "/curu/10011g.webp",
      "/curu/10011h.webp",
      "/curu/10011i.webp",
      "/curu/10011j.webp",
      "/curu/10011k.webp",
      "/curu/10011l.webp",
      "/curu/10011m.webp",
    ],
  },
  {
    id: 12,
    name: "ニューエラメッシュポケットショルダーバッグ",
    main: "フィラゴルフ",
    cent: "39",
    description: [
      "・ややハリのあるツイル素材",
      "・フロントにはロゴタグ、サイドにはロゴ刺繍を配置",
      "・便利な外側のジップ付きメッシュポケット",
      "・キーフックを内側に配備",
      "・背面にバッグ等への取り付けが可能なストラップを配備",
      "・取り外し可能なストラップでミニポーチやバッグインバッグとしても活躍",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA」。",
      "世界最高峰と言われ、老舗となった今でも、常に品質にこだわった「品質第一」主義を貫き、数多くのファンを魅了し続けている。",
      "中でもベースボールキャップは超有名。",
      "さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けている。コラボアイテムからも目が離せない。",
    ],
    price: "3960",
    sizeImg: "/curu/size012.png",
    size: ["フリー "],
    color: ["ブラック"],
    brand: "ニューエラ",
    catagory: "	ショルダーバッグ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "12325642",
    season: "F/W(秋冬)",
    images: [
      "/curu/10012.webp",
      "/curu/10012a.webp",
      "/curu/10012b.webp",
      "/curu/10012c.webp",
      "/curu/10012d.webp",
      "/curu/10012e.webp",
      "/curu/10012f.webp",
      "/curu/10012g.webp",
      "/curu/10012h.webp",
      "/curu/10012i.webp",
      "/curu/10012j.webp",
    ],
  },
  {
    id: 13,
    name: "ビームスゴルフ【撥水】グレンチェックシャカジップブルゾン",
    main: "トラヴィスマシュー",
    cent: "297",
    description: [
      "・クラシックなグレンチェック柄が上品な撥水ブルゾン",
      "・肌離れのいいメッシュ裏地を採用し、ベタつきにくく快適な着心地を実現",
      "・落ち着いた色合いのプリントが魅力的で、無地感覚でコーディネートしやすいのもポイント",
      "・急な小雨にも対応できる撥水加工",
      "・シャカっとしたタフタ生地",
      "・ポケットは2つ",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッション性と機能性を両立させたBEAMSのオリジナルブランド。",
      "アメカジやアウトドアのテイストを取り入れた＜オレンジレーベル＞と、伝統的なゴルフスタイルをシルエットや色使いで、",
      "モダナイズした＜パープルレーベル＞の2ラインに加え、遊び心に溢れたデザインでプレーを楽しく演出してくれるアクセサリー類のラインアップ。",
      "ゴルフがもっと楽しくなるビームスならではのゴルフスタイルは、着実にファンを増やしている。",
    ],
    price: "29700",
    sizeImg: "/curu/size013.png",
    size: ["16(S)", "18(M)", "20(L)"],
    color: ["ネイビー", "グレー"],
    brand: "ビームスゴルフ",
    catagory: "ブルゾン・ジャケット",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "85180070803",
    season: "F/W(秋冬)",
    images: [
      "/curu/10013.webp",
      "/curu/10013a.webp",
      "/curu/10013b.webp",
      "/curu/10013c.webp",
      "/curu/10013d.webp",
      "/curu/10013e.webp",
      "/curu/10013f.webp",
      "/curu/10013g.webp",
      "/curu/10013h.webp",
      "/curu/10013i.webp",
      "/curu/10013j.webp",
      "/curu/10013k.webp",
      "/curu/10013l.webp",
      "/curu/10013m.webp",
    ],
  },
  {
    id: 14,
    name: "ジュン＆ロぺ【UV撥水】サテンパイピング一体型ペチパンツ付きスカート",
    main: "パーリーゲイツ",
    cent: "253",
    description: [
      "・サラッとドライタッチな素材",
      "・重なりあった花びらのような裾デザインがレディーライク",
      "・ポケット口や裾に配されたサテンパイピングがさり気ないアクセントに",
      "・バックにロゴワッペン",
      "・レイヤード部分の裏地はメッシュになっていて、通気性◎",
      "・さらツルのペチパンツ付き",
      "・防透加工で安心",
      "・ポケットは4つ、ループ付き",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "25300",
    sizeImg: "/curu/size014.png",
    size: ["XS", "S", "M", "L"],
    color: ["ライトグレー", "オレンジ", "オフホワイト", "ネイビー"],
    brand: "ジュン＆ロぺ",
    catagory: "スカート",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "ERC44020",
    season: "F/W(秋冬)",
    images: [
      "/curu/10014.webp",
      "/curu/10014a.webp",
      "/curu/10014b.webp",
      "/curu/10014c.webp",
      "/curu/10014d.webp",
      "/curu/10014e.webp",
      "/curu/10014f.webp",
      "/curu/10014g.webp",
      "/curu/10014h.webp",
      "/curu/10014i.webp",
      "/curu/10014j.webp",
      "/curu/10014k.webp",
      "/curu/10014l.webp",
      "/curu/10014m.webp",
      "/curu/10014n.webp",
    ],
  },
  {
    id: 15,
    name: "ビームスゴルフ【BRIDGESTONE_GOLF】サイドロゴスパイクレスシューズ",
    main: "ニューエラ",
    cent: "209",
    description: [
      "・BRIDGESTONE GOLF(ブリヂストン ゴルフ)とのコラボ商品",
      "・シンプルでクラシックなデザインが魅力",
      "・サイドにはロゴがあしらわれている",
      "・「ゼロ・スパイク・バイター」の突起形状を踏襲した高いグリップ性能を持ち、撥水性のある国産クラリーノをアッパーに用いている",
      "・モノトーンで使いやすい",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッション性と機能性を両立させたBEAMSのオリジナルブランド。",
      "アメカジやアウトドアのテイストを取り入れた＜オレンジレーベル＞と、伝統的なゴルフスタイルをシルエットや色使いで、",
      "モダナイズした＜パープルレーベル＞の2ラインに加え、遊び心に溢れたデザインでプレーを楽しく演出してくれるアクセサリー類のラインアップ。",
      "ゴルフがもっと楽しくなるビームスならではのゴルフスタイルは、着実にファンを増やしている。",
    ],
    price: "20900",
    sizeImg: "/curu/size015.png",
    size: ["23.5cm ", "24.0cm", "25.5cm", "26.0cm ", "26.5cm"],
    color: ["ホワイト"],
    brand: "ビームスゴルフ",
    catagory: "シューズ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "81320101142",
    season: "All",
    images: [
      "/curu/10015.webp",
      "/curu/10015a.webp",
      "/curu/10015b.webp",
      "/curu/10015c.webp",
      "/curu/10015d.webp",
      "/curu/10015e.webp",
      "/curu/10015f.webp",
      "/curu/10015g.webp",
    ],
  },
  {
    id: 16,
    name: "セントアンドリュースバックルチェーンジャガードニットカーディガン",
    main: "ディッセンバーメイ",
    cent: "330",
    description: [
      "・上品なチェーン柄がオシャレ",
      "・フロントは柄、バックは無地になっている",
      "・胸元にはロゴ刺繍、バックにはロゴプレートを配置",
      "・ストレッチ性◎",
      "・ボタンがくるみボタンみたいで付け外ししやすい",
      "※この商品は、糸及び素材の特性上、引っかけ・ひっかかりにより糸がとびだしたり、ひきつれが生じやすい性質をもっています。表面の粗いものとの摩擦やひっかかりにご注意ください。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ゴルフ発祥の地であり、すべてのゴルファーが憧れる聖地“セント・アンドリュース”。 いつかその地で — ゴルフを愛する誰もが、そんな思いを抱く場所。その名を正式に冠することを許されたゴルフウェアブランド。 伝統的なスポーツとしてのスタイルを受け継ぎながら、華やかな社交場にふさわしいファッショナブルなスタイルも軽やかに楽しむ。 それは、伝統もモダンも愛する人のためのゴルフウェア。",
    ],
    price: "33000",
    sizeImg: "/curu/size016.png",
    size: ["S", "M", "L"],
    color: ["", "", ""],
    brand: "セントアンドリュース",
    catagory: "	カーディガン",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "0434272904",
    season: "F/W(秋冬)",
    images: [
      "/curu/10016.webp",
      "/curu/10016a.webp",
      "/curu/10016b.webp",
      "/curu/10016c.webp",
      "/curu/10016d.webp",
      "/curu/10016e.webp",
      "/curu/10016f.webp",
      "/curu/10016g.webp",
      "/curu/10016h.webp",
      "/curu/10016i.webp",
      "/curu/10016j.webp",
      "/curu/10016k.webp",
      "/curu/10016l.webp",
      "/curu/10016m.webp",
    ],
  },
  {
    id: 17,
    name: "ディッセンバーメイパネル切り替え一体型ペチパンツ付きスカート",
    main: "アディダスゴルフ",
    cent: "330",
    description: [
      "・サラッとした軽い素材",
      "・ハイストレッチ素材の一体型ペチパンツは程良い厚みがあって快適＆安心",
      "・配色デザインでスタイリッシュ",
      "・ウエストにはドロストコードを内蔵してあるので、好みのフィット感に調節可能",
      "・バックポケットにロゴ刺繍を配置",
      "・ポケットは4つ、ループ付き",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッションとスポーツを両軸に、音楽やストリートなどのサブカルチャーと歴史に裏打ちされたアーカイブを尊重したバランス感のあるハイブリッドウェアを展開。 身に着ける人の個性や環境に合わせ対応する製品を東京という都市の持つキャラクターをベースに表現します。 変えていくべきこと × 変えてはならないものを異なる要素を組み合わせて独自のスタイルを発信していくブランド。",
    ],
    price: "33000",
    sizeImg: "/curu/size017.png",
    size: ["S", "M"],
    color: ["ネイビー", "ブラウンベージュ", "オフホワイト"],
    brand: "	ディッセンバーメイ",
    catagory: "	スカート",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "24122535",
    season: "	F/W(秋冬)",
    images: [
      "/curu/10017.webp",
      "/curu/10017a.webp",
      "/curu/10017b.webp",
      "/curu/10017c.webp",
      "/curu/10017d.webp",
      "/curu/10017e.webp",
      "/curu/10017f.webp",
      "/curu/10017g.webp",
      "/curu/10017h.webp",
      "/curu/10017i.webp",
      "/curu/10017j.webp",
      "/curu/10017k.webp",
      "/curu/10017l.webp",
      "/curu/10017m.webp",
      "/curu/10017n.webp",
    ],
  },
  {
    id: 18,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "ジャックバニー",
    cent: "33",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "33000",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "ERR34100",
    season: "F/W(秋冬)",
    images: [
      "/curu/10018b.webp",
      "/curu/10018.webp",
      "/curu/10018a.webp",
      "/curu/10018c.webp",
      "/curu/10018d.webp",
      "/curu/10018e.webp",
      "/curu/10018f.webp",
    ],
  },
];

export default productData;
