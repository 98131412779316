import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  ホーム
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/terms" onClick={toTop}>
                  利用規約
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="localization">
          <div className="localization_left">
            <h3 className="footer___h3-sc-30ypqg-7 footer___title-sc-30ypqg-8 jnxbmz elTdpS">
              OTHER SERVICE
            </h3>
            <div className="foot__div">
              <ul className="footer___serviceList-sc-30ypqg-14 deCIGa">
                <li className="footer___service-sc-30ypqg-15 iorQOe">
                  <img alt="" src="/main/foot1.webp" />
                  <span className="footer___serviceMessage-sc-30ypqg-17 dZqPhN">
                    メンズゴルフウェアサイト
                  </span>
                  <dl>
                    <dt>サイトTOP</dt>
                    <dt>特集</dt>
                  </dl>
                </li>
                <li className="footer___service-sc-30ypqg-15 iorQOe">
                  <img alt="" src="/main/foot2.webp" />
                  <span className="footer___serviceMessage-sc-30ypqg-17 dZqPhN">
                    新作クラブが無料で試せる！
                  </span>
                  <dl>
                    <dt>打ちくらべセット</dt>
                    <dt>使いかた</dt>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
